
#portfolio {
  padding: 60px 0;
}

.portfolio-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.portfolio-items .col-sm-6 {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .portfolio-items .col-sm-6,
  .portfolio-items .col-md-4 {
    width: 100%; /* Full width on small screens */
  }
}

.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}


@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}

